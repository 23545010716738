export const environment = {
  production: false,
  apiUrls: {
    account: 'https://account.staging1.inlisto.ru',
    identity: 'https://identity.staging1.inlisto.ru',
    backoffice: 'https://backoffice-api.staging1.inlisto.ru',
    settings: 'https://settings.staging1.inlisto.ru'
  },
  yaMapKey: "9c6bc3ba-e346-40a2-9c52-279c10016f5e",
  autoFillAuthData: false
};
